import React from "react";
import { Link } from "react-router-dom";
import "../css/header.css";

function Header() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const toWho = () => {
    window.scrollTo({
      top: 1900,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="header">
        <div className="wrp-navi">
          <div className="fixed_txt_img">
            <span style={{ fontSize: "26px", fontWeight: "600" }}>m</span>
            <img
              width="62"
              height="37"
              src="https://d2ati23fc66y9j.cloudfront.net/world/ubuycom/images/org_Ubuy-Logo.png.webp"
              alt=""
            />
          </div>
          <ul>
            <li>
              <Link to="/" onClick={toTop}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/blog" onClick={toTop}>
                Blog
              </Link>
            </li>
            <li>
              <Link to="/about" onClick={toTop}>
                About us
              </Link>
            </li>
            <li>
              <Link to="/" onClick={toWho}>
                Who We Are
              </Link>
            </li>

            <li>
              <Link to="/contact" onClick={toTop}>
                Contact us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
