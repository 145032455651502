import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./Component/Main";
import { CartProvider } from "./Component/CartContext";
import Privacy from "./Component/Privacy";
import Contact from "./Component/Contact";
import Security from "./Component/Security";
import Certification from "./Component/Certification";
import Number from "./Component/Number";
import Business from "./Component/Business";
import About from "./Component/About";
import Blog from "./Component/Blog";
import Life from "./Component/Life";
import Enquire from "./Component/Enquire";

function App() {
  return (
    <CartProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />{" "}
          <Route path="/enquire" element={<Enquire />} />{" "}
          <Route path="/life" element={<Life />} />{" "}
          <Route path="/blog" element={<Blog />} />{" "}
          <Route path="/about" element={<About />} />
          <Route path="/business" element={<Business />} />
          <Route path="/mynumber" element={<Number />} />
          <Route path="/certification" element={<Certification />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/ship" element={<Security />} />
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;
