import React from "react";
import Footer from "./Footer";
import "../css/privacy.css";
import Header from "./Header";

function Enquire() {
  return (
    <div className="main-container">
      <Header />
      <>
        <main style={{ marginTop: "85px" }}>
          <div className="body-content clearfix">
            <div className="loader-spin-overlay">
              <div className="loader-spin" />
            </div>
            <link
              rel="stylesheet"
              href="https://www.ubuy.com.ua/skin/frontend/default/ubuycom-v1/css/cms-content-pages.css?v=30.1.113"
            />
            <section className="cms-content-pages corporate-enquiries">
              <div className="container-fluid pl-5 pr-5 mt-5">
                <div className="row justify-content-center">
                  <div className="col-xl-8 col-lg-9 col-md-10">
                    <h1
                      className="h1 poppins-font mb-5 mt-3 text-center"
                      style={{ lineHeight: "normal" }}
                    >
                      Business Purchases or Bulk Purchase Inquiries
                    </h1>
                    <div className="row">
                      <div className="col-md-6 align-self-left">
                        <figure className="text-center">
                          <img
                            alt="ubuy corporate enquiries"
                            className="img-fluid"
                            src="https://www.a.ubuy.com.kw/skin/frontend/default/ubuycom-v1/images/ubuy-bulk-purchase.png"
                          />
                        </figure>
                      </div>
                      <div className="col-md-6 align-self-left">
                        <h2 className="h2 poppins-font mb-1 mt-4 mb-0">
                          <strong>
                            Got Queries Regarding International Wholesale
                            Supply?
                          </strong>
                        </h2>
                        <ol className="pl-3 pt-2 pb-1">
                          <li className="mb-2">
                            Looking for global products in bulk for your
                            business? Ubuy, one of the leading international
                            wholesale suppliers can help you out! Let us know
                            your product details &amp;amp; quantity and we’ll
                            deliver right to your doorstep.
                          </li>
                          <li className="mb-2">
                            For business or wholesale purchase inquiries or
                            quotations, simply drop us an email at
                            team@mubuy.vip
                          </li>
                        </ol>
                        <h2 className="h2 poppins-font mb-1 mt-4 mb-0">
                          <strong>
                            Guidelines to Be Followed in Your Inquiry Email
                          </strong>
                        </h2>
                        <ol className="pl-3 pt-2 pb-1">
                          <li className="mb-2">
                            Do not forget to add the products’ quantity &amp;
                            IDs of your requested items!
                          </li>
                          <li className="mb-2">
                            Provide all your contact details!
                          </li>
                        </ol>
                        <hr />
                        <p className="h2-heading poppins-font mb-1 mt-4 mb-0">
                          <sup style={{ fontSize: 25, top: 0 }}>"</sup>
                          <i>
                            Dear Customers, make sure you are clear &amp;
                            understandable in your inquiry mail! Ubuy would love
                            to do business with you
                          </i>
                          <sup style={{ fontSize: 25, top: 0 }}>"</sup>
                        </p>
                        <hr />
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
        <div id="bottom-filter-brands" />
        <div className="move-up-cursor">
          {" "}
          <div id="go-to-top" className="go-top hvr-icon-wobble-vertical">
            {" "}
            <em className="fas fa-angle-double-up hvr-icon" />
          </div>{" "}
        </div>
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n        .download-app-card { width: 100%; position: fixed; padding: 20px; background: #fff; bottom: 0px; box-shadow: 0px 0px 10px 5px #eee; z-index: 999; font-family: 'Poppins', sans-serif; display: none;}\n        .download-app-card .icon { min-width: 50px; min-height: 50px; margin-right: 10px; height: 50px; border: solid 1px #eee; border-radius: 8px; padding: 5px;}\n        .download-app-card .header-content { font-size: 14px; color: #151515;}\n        .download-app-card .header-content p { color: #999; font-size: 12px; font-weight: 400; margin-bottom: 0px;}\n        .download-app-card .btn { font-size: 14px !important; padding: 8px 20px !important; width: 100%; font-weight:500;}\n        .download-app-card .btn-outline { border: solid 1px #eee !important; border-radius: 5px; color: #151515;}\n        .download-app-card .btn-dark { border: solid 1px #151515; background:#151515; border-radius: 5px; color: #fff;}\n        .btn-wrap .btn-outline{ margin-right:15px; }\n        .download-app-card .rating-stars { font-size: 12px; color: #ffb100;}\n        @media screen and (max-width: 820px) { \n            .download-app-card { display: block; }\n        }\n        @media screen and (max-width: 640px) { \n            .download-app-card .btn {  padding: 8px 10px !important; font-size: 12px; }\n        }\n    ",
          }}
        />
        <div className="intend-popup" style={{ display: "none" }} />
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n    .ip-button {\n        width: 40px;\n        height: 40px;\n        position: fixed;\n        right: auto;\n        left: 20px;\n        background: #fff;\n        box-shadow: 0 1px 6px rgb(32 33 36 / 28%);\n        text-align: center;\n        justify-content: center;\n        align-items: center;\n        display: flex;\n        border-radius: 8px;\n        border-top: 10px solid #FFB100;\n        font-weight: 600;\n        color: #151515;\n        font-family: 'Poppins', sans-serif;\n        z-index: 9;\n        bottom: 20px;\n        cursor: pointer;\n    }\n\n    @media screen and (max-width: 1600px) {\n        .ip-button {\n            left: 1rem;\n        }\n    }\n",
          }}
        />

        <link
          rel="stylesheet"
          href="https://ubuyanalytics.ubuy.com/ubuywebanalytics/webtrack/css/track.min.css?v=1.51"
        />
        <link
          rel="stylesheet"
          href="https://ubuyanalytics.ubuy.com/ubuywebanalytics/webtrack/css/vertical.css?v=1.51"
        />
      </>

      <Footer />
    </div>
  );
}

export default Enquire;
