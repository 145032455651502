import React from "react";
import Footer from "./Footer";
import "../css/privacy.css";
import Header from "./Header";
import { Link } from "react-router-dom";

function Number() {
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_box">
        <h2 className="privacy_entry">
          情報セキュリティ<span>SECURITY</span>
        </h2>
        <div className="privacy1_box">
          <Link title="サイバーコム株式会社へ行く" to="/">
            ホーム
          </Link>
          　&nbsp;&gt;&nbsp; 特定個人情報等の取扱い
        </div>
        <h3 className="pgtop">特定個人情報等の取扱い</h3>
        <div className="privacy_body">
          <div className="privacy_body">
            <h4>特定個人情報等の適正な取扱いに関する基本方針</h4>
            <p className="premr01">
              サイバーコム株式会社（以下「当社」という）は、「行政手続における特定の個人を識別するための番号の
              利用等に関する法律」（以下「マイナンバー法」という）に基づく個人番号（マイナンバー）及び特定個人情報（以下「特定
              個人情報等」という）の適正な取扱いの確保について組織として取り組むため本基本方針を定めます。
            </p>
            <ol class="mynum">
              <li>
                <span class="">関係法令・ガイドライン等の遵守</span>
                <br />
                当社は、マイナンバー法、「個人情報の保護に関する法律」、「個人情報の保護に関する法律についての経済産業分野を対象とするガイドライン」及び「特定個人情報の適正な取扱いに関するガイドライン（事業者編）」を遵守して、特定個人情報等の適正な取扱いを行います。
              </li>
              <li>
                <span class="">
                  特定個人情報の取得・利用・提供・廃棄について
                </span>
                <br />
                当社は、特定個人情報の利用目的を事業活動に必要な範囲で明確に定めるとともに、特定個人情報の適切な取得・利用・提供・廃棄を図ります。また、
                取得した特定個人情報を目的の範囲外で利用しないための措置を講じ、適切に取り扱います。
                <br />
                <br />
                以下の取扱事務に限定し利用いたします。
                <br />
                <p class="preml01">
                  ① 源泉徴収に関する事務
                  <br />
                  ② 個人住民税に関する事務
                  <br />
                  ③ 雇用保険関係事務
                  <br />
                  ④ 健康保険関連事務・厚生年金保険関連事務
                  <br />
                  ⑤ 国民年金第三号被保険者の届出事務
                  <br />
                  ⑥
                  財産形成住宅貯蓄・財産形成年金貯蓄に関する申告書、届出書及び申込書作成事務
                  <br />
                  ⑦ 持株会にかかる金融商品取引に関する法定書類の作成・提供事務
                  <br />
                  ⑧
                  配当金に関わる支払調書・単元未満株式の買取請求など株式の譲渡に関する支払調書等の作成事務
                  <br />⑨ その他、国が個人番号掲載を定めた帳票作成等に関する事務
                </p>
              </li>
              <li>
                <span class="">安全管理措置に関する事項</span>
                <br />
                特定個人情報の安全管理措置に関して、特定個人情報の漏えい、滅失または毀損を予防するため、合理的な安全対策を講じます。特定個人情報の取扱について、第三者に委託する場合は、特定個人情報等の保護について、当社と同等の水準を備えることを確認するとともに契約等により安全管理措置を講じることを定めた上で、委託先に対する必要かつ適切な管理をいたします。
              </li>
              <li>
                <span class="">
                  特定個人情報保護への継続的改善
                  <br />
                  当社は、特定個人情報保護が効果的に実施されるよう、特定個人情報に関する管理体制と仕組みについて継続的に見直すとともに、その改善に努めます。
                </span>
              </li>
              <li>
                <span class="">
                  苦情および相談への対応
                  <br />
                  当社は特定個人情報に関する苦情及び相談に対し、誠実かつ迅速に対応いたします。
                </span>
              </li>
            </ol>

            <p class="tright premr02">
              制定日：2012年3月26日
              <br />
              改定日：2015年4月14日
              <br />
              <br />
              サイバーコム株式会社
              <br />
              代表取締役社長　
              <img
                decoding="async"
                src="	https://www.cy-com.co.jp/corporate/wp-content/files/daihyo-name-2023.png"
                alt="代表取締役"
              />
            </p>
            <h5>特定個人情報お問い合わせ・苦情・相談総合窓口</h5>
            <p>
              サイバーコム株式会社
              <br />
              技術管理部
              <br />
              部長　井料 将人
              <br />
              〒231-0005　神奈川県横浜市中区本町 4-34　横浜本社ビル
              <br />
              TEL：045-681-6001（代）（受付時間：9:00～17:30）
              <br />
              E-mail： team@mubuy.vip{" "}
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Number;
