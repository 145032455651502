import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";

function Main() {
  const [activeIndex, setActiveIndex] = useState(null);
  const toggleDisplay = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <div className="main-container">
      <Header />
      <div className="main1_box">
        <div className="main1_box_inner">
          <div className="main1_box_inner_left">
            <h1>
              Ubuy: Delivering Cross-Border Items Since{" "}
              <span class="brand-color">2012</span>
            </h1>
            <p>
              A Successful International Shopping Platform For The Past{" "}
              <span class="brand-color">13 Years</span>{" "}
            </p>
          </div>

          <div className="main1_box_inner_right">
            <img
              src="https://d2ati23fc66y9j.cloudfront.net/world/ubuycom/images/map.png.webp"
              title="Map"
              alt="international shopping map ubuy"
              height="400"
              width="700"
              class="img-fluid"
            />
          </div>
        </div>
      </div>

      <div className="main2_box">
        <img
          src="https://d2ati23fc66y9j.cloudfront.net/d2c_2024/d2c_banner_web_eng.gif"
          width="100%"
          height="140"
          alt="Ubuy Best D2C Cross Border Brand of the Year 2024"
        ></img>
      </div>

      <div className="main3_box">
        <div className="main3_box_inner">
          <h2>Select Your Country</h2>
          <div className="main3_country">
            <div className="main3_country_inner">
              <h3>
                <button>
                  {" "}
                  MENA{" "}
                  <span class="disclosure__toggle">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon"
                      onClick={() => toggleDisplay(1)}
                      style={{
                        transform:
                          activeIndex === 1 ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <path
                        d="M20 8.5 12.5 16 5 8.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        fill="none"
                      ></path>
                    </svg>
                  </span>
                </button>
              </h3>
              <div
                className="main3_none_block"
                style={{
                  display: activeIndex === 1 ? "block" : "none",
                }}
              >
                <div className="main3_none_block_inner">
                  <img alt="" src="/head/box1.png" />
                </div>
              </div>
            </div>
            <div className="main3_country_inner">
              <h3>
                <button>
                  {" "}
                  AFRICA{" "}
                  <span class="disclosure__toggle">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon"
                      onClick={() => toggleDisplay(2)}
                      style={{
                        transform:
                          activeIndex === 2 ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <path
                        d="M20 8.5 12.5 16 5 8.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        fill="none"
                      ></path>
                    </svg>
                  </span>
                </button>
              </h3>
              <div
                className="main3_none_block"
                style={{
                  display: activeIndex === 2 ? "block" : "none",
                }}
              >
                <div className="main3_none_block_inner">
                  <img alt="" src="/head/box2.png" />
                </div>
              </div>
            </div>
            <div className="main3_country_inner">
              <h3>
                <button>
                  {" "}
                  ASIA{" "}
                  <span class="disclosure__toggle">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon"
                      onClick={() => toggleDisplay(3)}
                      style={{
                        transform:
                          activeIndex === 3 ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <path
                        d="M20 8.5 12.5 16 5 8.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        fill="none"
                      ></path>
                    </svg>
                  </span>
                </button>
              </h3>
              <div
                className="main3_none_block"
                style={{
                  display: activeIndex === 3 ? "block" : "none",
                }}
              >
                <div className="main3_none_block_inner">
                  <img alt="" src="/head/box3.png" />
                </div>
              </div>
            </div>
            <div className="main3_country_inner">
              <h3>
                <button>
                  {" "}
                  EUROPE{" "}
                  <span class="disclosure__toggle">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon"
                      onClick={() => toggleDisplay(4)}
                      style={{
                        transform:
                          activeIndex === 4 ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <path
                        d="M20 8.5 12.5 16 5 8.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        fill="none"
                      ></path>
                    </svg>
                  </span>
                </button>
              </h3>
              <div
                className="main3_none_block"
                style={{
                  display: activeIndex === 4 ? "block" : "none",
                }}
              >
                <div className="main3_none_block_inner">
                  <img alt="" src="/head/box4.png" />
                </div>
              </div>
            </div>
            <div className="main3_country_inner">
              <h3>
                <button>
                  {" "}
                  NORTH AMERICA{" "}
                  <span class="disclosure__toggle">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon"
                      onClick={() => toggleDisplay(5)}
                      style={{
                        transform:
                          activeIndex === 5 ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <path
                        d="M20 8.5 12.5 16 5 8.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        fill="none"
                      ></path>
                    </svg>
                  </span>
                </button>
              </h3>
              <div
                className="main3_none_block"
                style={{
                  display: activeIndex === 5 ? "block" : "none",
                }}
              >
                <div className="main3_none_block_inner">
                  <img alt="" src="/head/box5.png" />
                </div>
              </div>
            </div>
            <div className="main3_country_inner">
              <h3>
                <button>
                  {" "}
                  OCEANIA{" "}
                  <span class="disclosure__toggle">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon"
                      onClick={() => toggleDisplay(6)}
                      style={{
                        transform:
                          activeIndex === 6 ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <path
                        d="M20 8.5 12.5 16 5 8.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        fill="none"
                      ></path>
                    </svg>
                  </span>
                </button>
              </h3>
              <div
                className="main3_none_block"
                style={{
                  display: activeIndex === 6 ? "block" : "none",
                }}
              >
                <div className="main3_none_block_inner">
                  <img alt="" src="/head/box6.png" />
                </div>
              </div>
            </div>
            <div className="main3_country_inner">
              <h3>
                <button>
                  {" "}
                  SOUTH AMERICA{" "}
                  <span class="disclosure__toggle">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon"
                      onClick={() => toggleDisplay(7)}
                      style={{
                        transform:
                          activeIndex === 7 ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <path
                        d="M20 8.5 12.5 16 5 8.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        fill="none"
                      ></path>
                    </svg>
                  </span>
                </button>
              </h3>
              <div
                className="main3_none_block"
                style={{
                  display: activeIndex === 7 ? "block" : "none",
                }}
              >
                <div className="main3_none_block_inner">
                  <img alt="" src="/head/box7.png" />
                </div>
              </div>
            </div>
          </div>
          <div className="main3-ubuy-globe">
            <h2>Imported Products From Globe</h2>
            <div className="main3-ubuy-globe_flex">
              <div className="main3_internal">
                <div className="main3_internal_flex">
                  <div class="store-flag">
                    <span class="usa"></span>
                  </div>
                  <span>USA</span>
                </div>
              </div>
              <div className="main3_internal">
                <div className="main3_internal_flex">
                  <div class="store-flag">
                    <span class="uk"></span>
                  </div>
                  <span>UK</span>
                </div>
              </div>
              <div className="main3_internal">
                <div className="main3_internal_flex">
                  <div class="store-flag">
                    <span class="japan"></span>
                  </div>
                  <span>Japan</span>
                </div>
              </div>
              <div className="main3_internal">
                <div className="main3_internal_flex">
                  <div class="store-flag">
                    <span class="korea"></span>
                  </div>
                  <span>Korea</span>
                </div>
              </div>
              <div className="main3_internal">
                <div className="main3_internal_flex">
                  <div class="store-flag">
                    <span class="china"></span>
                  </div>
                  <span>China</span>
                </div>
              </div>
              <div className="main3_internal">
                <div className="main3_internal_flex">
                  <div class="store-flag">
                    <span class="hk"></span>
                  </div>
                  <span>Hong Kong</span>
                </div>
              </div>
              <div className="main3_internal">
                <div className="main3_internal_flex">
                  <div class="store-flag">
                    <span class="turkey"></span>
                  </div>
                  <span>Turkey</span>
                </div>
              </div>
              <div className="main3_internal">
                <div className="main3_internal_flex">
                  <div class="store-flag">
                    <span class="eu"></span>
                  </div>
                  <span>Europe</span>
                </div>
              </div>
              <div className="main3_internal">
                <div className="main3_internal_flex">
                  <div class="store-flag">
                    <span class="india"></span>
                  </div>
                  <span>India</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main4_box">
        <h2 className="main4_box_h2">Who We Are</h2>
        <div className="main4_box_one">
          <div class="main4_box_one_left">
            <img
              src="https://d2ati23fc66y9j.cloudfront.net/world/ubuycom/images/search-platform.png.webp"
              title="search platform"
              alt="global shopping platform ubuy"
              class="img-fluid"
            />
          </div>
          <div class="main4_box_one_right">
            <p>
              For over a decade, Ubuy as a leading retail search engine platform
              has been serving customers in over{" "}
              <span class="brand-color">180+ countries</span> around the globe.
              With its core focus on ‘U’ (read:You), Ubuy enables consumers to
              buy unique, luxury and distinct products from top-notch
              international brands in the most hassle-free manner. The intuitive
              platform across website and app allows a never-experienced-before
              feel every time you checkout your carts filled up to the brim!
            </p>
          </div>
        </div>

        <div className="main4_box_two">
          <div className="main4_box_two_inner">
            <div className="main4_box_two_inner_body">
              <div class="card-text-area">
                <h2 class="card-title">Our Brand</h2>
                <p class="card-text mt-4">
                  The go-to destination for every online buyer, we offer
                  first-rate shipping services and quick doorstep product
                  deliveries.
                </p>
              </div>
              <div class="about-logos-images">
                {" "}
                <span class="brand-icon-img about-us-sprite-icon-width"></span>
              </div>
            </div>
          </div>
          <div className="main4_box_two_inner">
            <div className="main4_box_two_inner_body">
              <div class="card-text-area">
                <h2 class="card-title">Our Vision</h2>
                <p class="card-text mt-4">
                  To emerge as the most reliable luxury e-commerce platform in
                  the international shopping sphere by creating a new glocal for
                  consumers worldwide.
                </p>
              </div>
              <div class="about-logos-images ">
                {" "}
                <span class="vision-icon-img about-us-sprite-icon-width "></span>
              </div>
            </div>
          </div>
          <div className="main4_box_two_inner">
            <div className="main4_box_two_inner_body">
              <div class="card-text-area">
                <h2 class="card-title">Our Mission</h2>
                <p class="card-text mt-4">
                  To act as a catalyst in the countries across the globe where
                  top brands are inaccessible, and provide high-quality, premium
                  products to the consumers.
                </p>
              </div>
              <div class="about-logos-images">
                {" "}
                <span class="mission-icon-img about-us-sprite-icon-width"></span>
              </div>
            </div>
          </div>
        </div>

        <h2 className="main4_box_h2">
          Why Consumers Admire Us As The No. 1 Cross Border Shopping Platform
          for Imported Products in 180+ Countries
        </h2>
      </div>

      <div className="main5_box">
        <div class="container">
          <img
            src="https://d2ati23fc66y9j.cloudfront.net/world/ubuycom/images/shopping-platform.png.webp"
            title="shopping platform"
            alt="retail search engine platform ubuy"
            class="rounded mx-auto d-block img-fluid"
          />
        </div>
      </div>

      <div className="main6_box">
        <div className="container">
          <img alt="" src="/head/main1.png" />
        </div>
        <div className="container">
          <h2>Shopping Guide: How You Can Purchase From Us</h2>
          <img alt="" src="/head/main2.png" />
        </div>
      </div>

      <div className="main3_box">
        <div className="main3_box_inner">
          <h2>Frequently Asked Questions</h2>
          <div className="main3_country">
            <div className="main3_country_inner">
              <h3>
                <button>
                  {" "}
                  What Should I Do To Speed Up The Delivery Of My Order On Ubuy?{" "}
                  <span class="disclosure__toggle">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon"
                      onClick={() => toggleDisplay(1)}
                      style={{
                        transform:
                          activeIndex === 1 ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <path
                        d="M20 8.5 12.5 16 5 8.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        fill="none"
                      ></path>
                    </svg>
                  </span>
                </button>
              </h3>
              <div
                className="main3_none_block"
                style={{
                  display: activeIndex === 1 ? "block" : "none",
                }}
              >
                <div className="main3_none_block_inner ">
                  <p>
                    If you want your order delivered faster on Ubuy, you can
                    upgrade to our express shipping service during your
                    purchase. Ubuy's express shipping option is a premium
                    delivery service that prioritises your order and delivers it
                    within 3-6 business days. Select the express shipping option
                    during checkout to use this service. In some cases, express
                    shipping may require an additional charge, depending on your
                    location and order size. Important to note that availability
                    of the product, shipping location, and other factors may
                    influence delivery times.
                  </p>
                </div>
              </div>
            </div>
            <div className="main3_country_inner">
              <h3>
                <button>
                  {" "}
                  Is It Necessary to Have an Account to Shop on Ubuy?{" "}
                  <span class="disclosure__toggle">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon"
                      onClick={() => toggleDisplay(2)}
                      style={{
                        transform:
                          activeIndex === 2 ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <path
                        d="M20 8.5 12.5 16 5 8.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        fill="none"
                      ></path>
                    </svg>
                  </span>
                </button>
              </h3>
              <div
                className="main3_none_block"
                style={{
                  display: activeIndex === 2 ? "block" : "none",
                }}
              >
                <div className="main3_none_block_inner">
                  <p>
                    The best part of shopping with Ubuy is that you can place an
                    order as a guest without creating an account. However,
                    creating an account on Ubuy has several benefits. It allows
                    you to track your orders, save your shipping and billing
                    addresses for faster checkout, and receive special offers
                    and discounts.
                  </p>
                  <p>
                    You can create an account on Ubuy with a few easy steps.
                    Just click on the "Create an Account" button located at the
                    top right of the Ubuy homepage, then simply enter your
                    details. Once you have successfully created an account, You
                    can log in easily to your account at any time to Shop for
                    your favourite products.
                  </p>
                </div>
              </div>
            </div>
            <div className="main3_country_inner">
              <h3>
                <button>
                  {" "}
                  What Makes Ubuy Different from Other Shopping Platforms?{" "}
                  <span class="disclosure__toggle">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon"
                      onClick={() => toggleDisplay(3)}
                      style={{
                        transform:
                          activeIndex === 3 ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <path
                        d="M20 8.5 12.5 16 5 8.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        fill="none"
                      ></path>
                    </svg>
                  </span>
                </button>
              </h3>
              <div
                className="main3_none_block"
                style={{
                  display: activeIndex === 3 ? "block" : "none",
                }}
              >
                <div className="main3_none_block_inner">
                  <p>
                    Ubuy is unique among shopping platforms because of several
                    factors:
                  </p>
                  <p>
                    <strong>Wide range of products: </strong>Ubuy offers a
                    diverse range of products across various categories,
                    including electronics, fashion, home and kitchen, beauty and
                    personal care, and many more.
                  </p>
                  <p>
                    <strong>Global reach: </strong>Ubuy has a presence in more
                    than 180 countries, allowing customers to access products
                    from all over the world through the site.
                  </p>
                  <p>
                    <strong>Competitive pricing: </strong>Ubuy's products are
                    offered at competitive prices accompanied by frequent
                    discounts and promotions which make them more affordable.
                  </p>
                  <p>
                    <strong>Secure payment options: </strong>Ubuy offers secure
                    payment options, including credit card, debit card, and
                    PayPal, that protect customers' personal and financial
                    information.
                  </p>
                  <p>
                    Overall, Ubuy's wide range of products, global reach,
                    competitive pricing, express shipping, secure payment
                    options, and excellent customer service make it a standout
                    shopping platform for customers around the world.
                  </p>
                </div>
              </div>
            </div>
            <div className="main3_country_inner">
              <h3>
                <button>
                  {" "}
                  How Can I Contact Ubuy For Queries Regarding My Online Orders?{" "}
                  <span class="disclosure__toggle">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon"
                      onClick={() => toggleDisplay(4)}
                      style={{
                        transform:
                          activeIndex === 4 ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <path
                        d="M20 8.5 12.5 16 5 8.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        fill="none"
                      ></path>
                    </svg>
                  </span>
                </button>
              </h3>
              <div
                className="main3_none_block"
                style={{
                  display: activeIndex === 4 ? "block" : "none",
                }}
              >
                <div className="main3_none_block_inner">
                  <p>
                    You can contact us in case of any query regarding placing a
                    new order or knowing the status of an ongoing order. You can
                    contact us through different mediums such as 24*7 voice and
                    chat support in English, messaging us on social media on
                    Facebook, Twitter, WhatsApp, and Instagram or mailing your
                    concern at team@mubuy.vip.
                  </p>
                </div>
              </div>
            </div>
            <div className="main3_country_inner">
              <h3>
                <button>
                  {" "}
                  Which countries does Ubuy ship to?{" "}
                  <span class="disclosure__toggle">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon"
                      onClick={() => toggleDisplay(5)}
                      style={{
                        transform:
                          activeIndex === 5 ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <path
                        d="M20 8.5 12.5 16 5 8.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        fill="none"
                      ></path>
                    </svg>
                  </span>
                </button>
              </h3>
              <div
                className="main3_none_block"
                style={{
                  display: activeIndex === 5 ? "block" : "none",
                }}
              >
                <div className="main3_none_block_inner">
                  <p>
                    Ubuy is a global online shopping platform that ships to over
                    180+ countries worldwide. Some of the countries that Ubuy
                    ships, Include Kuwait, Qatar, Canada, United Kingdom,
                    Australia, New Zealand, India, Saudi Arabia, United Arab
                    Emirates, and many more.
                  </p>
                </div>
              </div>
            </div>
            <div className="main3_country_inner">
              <h3>
                <button>
                  {" "}
                  How can I check if Ubuy delivers to my Pin Code?{" "}
                  <span class="disclosure__toggle">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon"
                      onClick={() => toggleDisplay(6)}
                      style={{
                        transform:
                          activeIndex === 6 ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <path
                        d="M20 8.5 12.5 16 5 8.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        fill="none"
                      ></path>
                    </svg>
                  </span>
                </button>
              </h3>
              <div
                className="main3_none_block"
                style={{
                  display: activeIndex === 6 ? "block" : "none",
                }}
              >
                <div className="main3_none_block_inner">
                  <p>
                    To check if Ubuy delivers to your PIN code, Follow these
                    easy steps:
                  </p>
                  <ol>
                    <li>
                      Select your country from the above country search section.
                    </li>
                    <li>
                      Browse and select the product(s) you want to purchase.
                    </li>
                    <li>
                      Add the product(s) to your cart and proceed to the
                      checkout page.
                    </li>
                    <li>
                      On the checkout page, enter your shipping details,
                      address, including your PIN code.
                    </li>
                  </ol>
                  <p>
                    If we deliver to your PIN code then we will provide you
                    details with the available shipping options and estimated
                    delivery time. If the product is not deliverable to your PIN
                    code, you will receive an error message indicating that
                    delivery is not available for your selected location.
                  </p>
                </div>
              </div>
            </div>
            <div className="main3_country_inner">
              <h3>
                <button>
                  {" "}
                  What Are The Most Common Reasons Why A Payment Is Declined?{" "}
                  <span class="disclosure__toggle">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon"
                      onClick={() => toggleDisplay(7)}
                      style={{
                        transform:
                          activeIndex === 7 ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <path
                        d="M20 8.5 12.5 16 5 8.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        fill="none"
                      ></path>
                    </svg>
                  </span>
                </button>
              </h3>
              <div
                className="main3_none_block"
                style={{
                  display: activeIndex === 7 ? "block" : "none",
                }}
              >
                <div className="main3_none_block_inner">
                  <p>
                    Following are some of the most common reasons for payment
                    getting declined.
                  </p>
                  <ol>
                    <li>
                      <strong>
                        Incorrect payment method for the given region:{" "}
                      </strong>
                      If the payment method you have selected is not applicable
                      in your region or the region you are ordering from then
                      the payment will be declined.
                    </li>
                    <li>
                      <strong>Incorrect User details: </strong>The payment
                      process will be declined if the details added by the user
                      are not correct.
                    </li>
                    <li>
                      <strong>Technical Issues: </strong>The payment will also
                      be declined in case there are some technical issues going
                      on with the bank being used for the transaction.
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="main3_country_inner">
              <h3>
                <button>
                  {" "}
                  Can I Search for Products In the Local Language?{" "}
                  <span class="disclosure__toggle">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                      role="presentation"
                      class="icon"
                      onClick={() => toggleDisplay(8)}
                      style={{
                        transform:
                          activeIndex === 8 ? "rotate(180deg)" : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                      }}
                    >
                      <path
                        d="M20 8.5 12.5 16 5 8.5"
                        stroke="currentColor"
                        stroke-width="1.5"
                        fill="none"
                      ></path>
                    </svg>
                  </span>
                </button>
              </h3>
              <div
                className="main3_none_block"
                style={{
                  display: activeIndex === 8 ? "block" : "none",
                }}
              >
                <div className="main3_none_block_inner">
                  <p>
                    Yes, It’s very easy to search for products in your preferred
                    local language at Ubuy. Our Website and Application support
                    multiple languages option including Arabic, French, German,
                    Spanish, and many other languages.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
