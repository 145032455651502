import React from "react";
import Footer from "./Footer";
import "../css/privacy.css";
import Header from "./Header";

function Privacy() {
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_box">
        <div className="privacy_box_inner">
          <div className="privacy_body">
            <h1>Terms & Conditions</h1>
            <h2>
              <strong>Conditions of Use:</strong>
            </h2>
            <p>
              Please read the following terms and conditions carefully before
              using this Website. Accessing and using this Website indicates
              your agreement to all the terms and conditions and other
              applicable law. If you do not agree to these terms and conditions,
              please do not use this site.
            </p>
            <h2>
              <strong>Copyright:</strong>
            </h2>
            <p>
              All material on this site, including images, illustrations, audio
              clips, and video clips, are protected by copyrights, trademarks,
              and other intellectual property rights that are owned and
              controlled by Ubuy.co. Permission is granted to electronically
              copy and print hard copy portions of this site for the sole
              purpose of placing an order with Ubuy.Co or purchasing Ubuy.Co
              products. You may display and, subject to any expressly stated
              restrictions or limitations relating to specific material,
              download or print portions of the material from the different
              areas of the site solely for your own non-commercial use, or to
              place an order with Ubuy.co or to purchase Ubuy.Co products. Any
              other use, including but not limited to the reproduction,
              distribution, display or transmission of the content of this site
              is strictly prohibited, unless authorized by Ubuy.Co. You further
              agree not to change or delete any proprietary notices from
              materials downloaded from the site.
            </p>
            <h2>
              <strong>Trademark:</strong>
            </h2>
            <p>
              The trademarks, logos and service marks displayed on the Website
              ("Marks") are the property of Ubuy.Co. You are not permitted to
              use the Marks without the prior consent of Ubuy.Co.
            </p>
            <h2>
              <strong>Warranty Disclaimer:</strong>
            </h2>
            <p>
              The Website, Service, Content, User Content are provided by Ubuy
              on an "As Is" basis without warranty of any kind, express,
              implied, statutory or without, including the implied warranties of
              title, Non-Infringement, merchantability or fitness for a
              particular purpose.. Ubuy.Co does not represent or warrant that
              the functions contained in the site will be uninterrupted or
              error-free, that the defects will be corrected, or that this site
              or the server that makes the site available are free of viruses or
              other harmful components. Ubuy.Co does not make any warranties or
              representations regarding the use of the materials in this site in
              terms of their correctness, accuracy, adequacy, usefulness,
              timeliness, reliability or otherwise. Some states do not permit
              limitations or exclusions on warranties, so the above limitations
              may not apply to you.
            </p>
            <h2>
              <strong>Limitation of Liability:</strong>
            </h2>
            <p>
              Ubuy.Co shall not be liable for any special or consequential
              damages that result from the use of, or the inability to use, the
              materials on this site or the performance of the products, even if
              Ubuy.Co has been advised of the possibility of such damages.
              Applicable law may not allow the limitation of exclusion of
              liability or incidental or consequential damages, so the above
              limitation or exclusion may not apply to you.
            </p>
            <h2>
              <strong>Typographical Errors:</strong>
            </h2>
            <p>
              In the event that a Ubuy.Co product is mistakenly listed at an
              incorrect price, Ubuy.Co reserves the right to refuse or cancel
              any orders placed for product listed at the incorrect
              price.Ubuy.Co reserves the right to refuse or cancel any such
              orders whether or not the order has been confirmed and your credit
              card charged. If your credit card has already been charged for the
              purchase and your order is cancelled, Ubuy.Co shall issue a credit
              to your credit card account in the amount of the incorrect price.
            </p>
            <h2>
              <strong>Termination:</strong>
            </h2>
            <p>
              These terms and conditions are applicable to you upon your
              accessing the site and/or completing the registration or shopping
              process. These terms and conditions, or any part of them, may be
              terminated by Ubuy.Co without notice at any time, for any reason.
              The provisions relating to Copyrights, Trademark, Disclaimer,
              Limitation of Liability, Indemnification and Miscellaneous, shall
              survive any termination. Ubuy.Co may deliver notice to you by
              means of e-mail, a general notice on the site, or by other
              reliable method to the address you have provided to Ubuy.Co.
            </p>
            <h2>
              <strong>Miscellaneous:</strong>
            </h2>
            <p>
              Your use of this site shall be governed in all respects by the
              laws of the state of Kuwait., without regard to choice of law
              provisions, and not by the 1980 U.N. Convention on contracts for
              the international sale of goods. You agree that jurisdiction over
              and venue in any legal proceeding directly or indirectly arising
              out of or relating to this site (including but not limited to the
              purchase of Ubuy.Co products) shall be in the State of Kuwait. Any
              cause of action or claim you may have with respect to the site
              (including but not limited to the purchase of Ubuy.Co products)
              must be commenced within one (1) month after the claim or cause of
              action arises. Ubuy.Co's failure to insist upon or enforce strict
              performance of any provision of these terms and conditions shall
              not be construed as a waiver of any provision or right. Neither
              the course of conduct between the parties nor trade practice shall
              act to modify any of these terms and conditions.Ubuy.Co may assign
              its rights and duties under this Agreement to any party at any
              time without notice to you.
            </p>
            <h2>
              <strong>Use of Site:</strong>
            </h2>
            <p>
              Harassment in any manner or form on the site, including via
              e-mail, chat, or by use of obscene or abusive language, is
              strictly forbidden. Impersonation of others, including a Ubuy.Co
              or other licensed employee, host, or representative, as well as
              other members or visitors on the site is prohibited. You may not
              upload to, distribute, or otherwise publish through the site any
              content which is libelous, defamatory, obscene, threatening,
              invasive of privacy or publicity rights, abusive, illegal, or
              otherwise objectionable which may constitute or encourage a
              criminal offense, violate the rights of any party or which may
              otherwise give rise to liability or violate any law. You may not
              upload commercial content on the site or use the site to solicit
              others to join or become members of any other commercial online
              service or other organization.
            </p>
            <h2>
              <strong>Participation Disclaimer:</strong>
            </h2>
            <p>
              Ubuy.Co does not and cannot review all communications and
              materials posted to or created by users accessing the site, and is
              not in any manner responsible for the content of these
              communications and materials. You acknowledge that by providing
              you with the ability to view and distribute user-generated content
              on the site, Ubuy.Co is merely acting as a passive conduit for
              such distribution and is not undertaking any obligation or
              liability relating to any contents or activities on the site. You
              agree to indemnify, defend, and hold harmless Ubuy.Co, its
              officers, directors, employees, agents, licensors and suppliers
              (collectively the "Service Providers") from and against all
              losses, expenses, damages and costs, including reasonable
              attorneys" fees, resulting from any violation of these terms and
              conditions or any activity related to your account (including
              negligent or wrongful conduct) by you or any other person
              accessing the site using your Internet account.
            </p>
            <h2>
              <strong>Indemnification:</strong>
            </h2>
            <p>
              You agree to indemnify, defend, and hold harmless Ubuy.Co, its
              officers, directors, employees, agents, licensors and suppliers
              (collectively the &amp;quot;Service Providers&amp;quot;) from and
              against all losses, expenses, damages and costs, including
              reasonable attorneys&amp;quot; fees, resulting from any violation
              of these terms and conditions or any activity related to your
              account (including negligent or wrongful conduct) by you or any
              other person accessing the site using your Internet account.
            </p>
            <h2>
              <strong>Third-Party Links:</strong>
            </h2>
            <p>
              In an attempt to provide increased value to our visitors, Ubuy.Co
              may link to sites operated by third parties. However, even if the
              third party is affiliated with Ubuy.Co, Ubuy.Co has no control
              over these linked sites, all of which have separate privacy and
              data collection practices, independent of Ubuy.Co. These linked
              sites are only for your convenience and therefore you access them
              at your own risk. Nonetheless, Ubuy.Co seeks to protect the
              integrity of its web site and the links placed upon it and
              therefore requests any feedback on not only its own site, but for
              sites it links to as well (including if a specific link does not
              work).
            </p>
            <p>
              <strong>Notice:</strong>
            </p>
            <p>
              The Ubuy Website is a Global Search Engine. We source the product
              from the original retailer/distributer. The difference between the
              cost of product and what is collected is the sourcing fee.
            </p>
            <p>
              Not all products listed on the Ubuy website may be available for
              purchase in your respective country of destination. Ubuy makes no
              promises or guarantees as to the availability of any product
              listed on the website as being available in the customer’s
              respective country of destination.
            </p>
            <p>
              All purchases made on the Ubuy website are subject to the duties,
              regulations, and laws of the country of destination and any
              country through which said purchased product(s) may transit
              without exception.
            </p>
            <p>
              Ubuy makes no affirmative representations, promises, or guarantees
              as to the legality of any product for sale on the Ubuy website in
              the purchaser’s respective country of destination. Any costs,
              fines or penalties (civil and criminal) which may be imposed by
              any country or jurisdiction, shall be the exclusive liability of
              the purchaser of any such product(s) and/or the “Importer of
              Record” into the “Destination Country” as defined herein.
            </p>
            <h2>
              <strong>Disclaimer:</strong>
            </h2>
            <ol class="pl-3 pt-2 pb-1 terms-listing">
              <li>
                Product manuals, instructions, and safety warnings which may
                have been included in the initial sale of or when purchased by
                Ubuy also, may not be contained with the product when received
                by you the Ubuy customer or if included, may not be included in
                the language of the destination country. Further, the products
                (and accompanying materials – if any) may not be designed in
                accordance with destination country standards, specifications,
                and labeling requirements.
              </li>
              <li>
                The product(s) purchased by the Ubuy customer through the Ubuy
                website, may not conform to the destination country voltage and
                other electrical standards (requiring use of an adapter or
                converter if appropriate). For example, electronic products sold
                in US stores operate on (110-120) volts, a step-down power
                converter is required for the smooth device function. It is
                mandatory to know the wattage of the device in order to choose
                the appropriate power converter.
              </li>
              <li>
                As to each such purchase made by the customer through the Ubuy
                Website, the recipient shall be in the destination country in
                all instances shall be the “Importer of Record” and must comply
                with all laws and regulations of said destination country for
                the Product(s) purchased through the Ubuy Website.
              </li>
              <li>
                The customer who purchases Product(s) through the Ubuy Website
                and/or the recipient of the product(s) in the destination
                country is/are solely responsible for assuring that the
                product(s) can be lawfully imported to the destination country
                as Ubuy and its affiliates make no affirmations, representations
                or promises of any type regarding the legality of importing any
                Product(s) purchased on the Ubuy Website into any country in the
                world.
              </li>
              <li>
                Ubuy reserves the right at any time to remove any product(s) or
                product(s) ever listed on the Ubuy website or restrict the
                visibility/viewability or ability to purchase any product from
                the website as Ubuy sees fit, at any time without explanation.
                The removal of any product(s) or product by Ubuy from the Ubuy
                website shall in no way in any instance be deemed as any type of
                admission of liability, wrongdoing, guilt or acknowledgment of
                any violation of any statute, tax or law as to any nation or
                jurisdiction in the world.
              </li>
              <li>
                Ubuy is a reseller of products through its website. Ubuy
                purchases products from retailers and/or third-party sellers for
                re-sale to Ubuy customers through the website. Unless otherwise
                specified, Ubuy is not affiliated with the manufacturers of the
                products found on the website and products found here are
                independently sourced on behalf of the buyer.
              </li>
              <li>
                All products purchased from the Ubuy website are sold “As Is”,
                Subject to Any Warranty or Guarantee that may still be
                enforceable as to the manufacturer (if any). Ubuy makes no
                warranties, promises or assurances regarding the quality or
                origination of any product sold through the website.
              </li>
              <li>
                While Ubuy provides products that it acquires through genuine
                sources, as a non- affiliated third-party source of products,
                all third-party products, company names and logos are trademark™
                or registered® trademarks and remain the property of their
                respective holders. Use of them does not imply any affiliation
                with or endorsement by them.
              </li>
              <li>
                Manufacturer service options and warranties, which may have come
                with the product when originally sold, may also not be available
                for the Ubuy customer, due to expiration of said service option
                or the voiding or voidability by the manufacturer of said
                service option upon the resale of the Product by Ubuy through
                its Website to the Ubuy purchaser.
              </li>
            </ol>
            <h2>
              <strong>AI-Driven Content:</strong>
            </h2>
            <p>
              At Ubuy, we embrace the cutting-edge power of AI to craft dynamic
              content to ensure an engaging &amp; personalized experience for
              our valued customers. For instance, we carefully tailor thousands
              of customer reviews and present them in a meticulously organized
              manner on our website and application.
            </p>
            <h2>
              <strong style={{ color: "#990000" }}>
                Governing Law and Jurisdiction:
              </strong>
            </h2>
            <p>
              These Terms of Use and all transactions entered into on or through
              the Website and the relationship between You and Ubuy shall be
              governed in accordance with the laws of Kuwait without reference
              to conflict of laws principles.
            </p>
            <p>
              Ubuy will not deal or provide any services/products to any of OFAC
              sanctions countries in accordance with the law of Kuwait.
            </p>
            <p>
              Ubuy Co W.L.L and/or their affiliates ("Ubuy") provide website
              features, payments solutions, Intellectual property, and other
              products and services to you when you visit or shop at Ubuy
              international websites (the "website").
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy;
