import React from "react";
import Footer from "./Footer";
import "../css/privacy.css";
import Header from "./Header";
import { Link } from "react-router-dom";

function Life() {
  return (
    <div className="main-container">
      <Header />
      <>
        <div>
          <div className="container" style={{ marginTop: "85px" }}>
            <div className="row" style={{ flexDirection: "row-reverse" }}>
              <div className="col-lg-7 col-xxl-6 right-sec mt-5 pt-4 position-relative">
                <div className="d2c-logo-new position-absolute">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/D2C-award-logov1.png"
                    alt="D2C-award-logo"
                  />
                </div>
                <div className="doted-bg position-relative">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/dotted-bg.svg"
                    alt="dotted-bg"
                  />
                </div>
                <div className="main-slider-new d-flex overflow-hidden position-relative z-1">
                  <div className="slide w-100 position-absolute d-flex gap-3 h-100">
                    <div className="img-sec d-flex align-items-start">
                      <img
                        width={686}
                        height={730}
                        src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/main-slide-banner/1.jpg.webp"
                        alt="life at ubuy"
                      />
                    </div>
                    <div className="img-sec d-flex align-items-end">
                      <img
                        width={686}
                        height={730}
                        src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/main-slide-banner/2.jpg.webp"
                        alt="life at ubuy"
                      />
                    </div>
                  </div>
                  <div className="slide w-100 position-absolute d-flex gap-3 h-100">
                    <div className="img-sec d-flex align-items-start">
                      <img
                        width={686}
                        height={730}
                        src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/main-slide-banner/3.jpg.webp"
                        alt="life at ubuy"
                      />
                    </div>
                    <div className="img-sec d-flex align-items-end">
                      <img
                        width={686}
                        height={730}
                        src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/main-slide-banner/4.jpg.webp"
                        alt="life at ubuy"
                      />
                    </div>
                  </div>
                  <div className="slide w-100 position-absolute d-flex gap-3 h-100">
                    <div className="img-sec d-flex align-items-start">
                      <img
                        width={686}
                        height={730}
                        src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/main-slide-banner/5.jpg.webp"
                        alt="life at ubuy"
                      />
                    </div>
                    <div className="img-sec d-flex align-items-end">
                      <img
                        width={686}
                        height={730}
                        src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/main-slide-banner/6.jpg.webp"
                        alt="life at ubuy"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link
                rel="preconnect"
                href="https://fonts.gstatic.com"
                crossOrigin=""
              />
              <link
                rel="preload"
                href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400"
                as="style"
                onload="this.onload=null;this.rel='stylesheet'"
              />
              <link
                href="https://life.ubuy.com/assests-v1/css/owl.carousel.min.css"
                rel="stylesheet"
              />
              <link
                href="https://life.ubuy.com/assests-v1/css/all.min.css"
                rel="stylesheet"
              />
              <div className="col-lg-5 col-xxl-6">
                <div className="mt-20">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuy-stands-for.svg"
                    alt="ubuy-stands-for"
                    width={460}
                    height={340}
                    className="img-fluid ubuy-stands-for"
                  />
                </div>
                <div className="main-slider owl-carousel text-slider owl-theme">
                  <div className="item">
                    <h1 className="text">
                      <span className="text-active yellow">unique,</span>{" "}
                      ultimate, unstoppable, unlimited, unequaled
                    </h1>
                  </div>
                  <div className="item">
                    <h1 className="text">
                      unique,
                      <span className="text-active yellow">ultimate,</span>{" "}
                      unstoppable, unlimited, unequaled
                    </h1>
                  </div>
                  <div className="item">
                    <h1 className="text">
                      unique, ultimate,
                      <span className="text-active yellow">unstoppable,</span>
                      unlimited, unequaled
                    </h1>
                  </div>
                  <div className="item">
                    <h1 className="text">
                      unique, ultimate, unstoppable,
                      <span className="text-active yellow">
                        unlimited,
                      </span>{" "}
                      unequaled
                    </h1>
                  </div>
                  <div className="item">
                    <h1 className="text">
                      unique, ultimate, unstoppable, unlimited,
                      <span className="text-active yellow">unequaled </span>
                    </h1>
                  </div>
                </div>
                <p className="small-content">
                  A one-stop destination for all your shopping needs
                </p>
              </div>
              <div className="doted-bg-1 position-relative">
                <img
                  src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/dotted-bg.svg"
                  alt="dotted-bg"
                />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mt-8 flex-lg-row-reverse">
              <div className="col-lg-3">
                <div className="signature">
                  <img
                    width={280}
                    height={155}
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/barand/Brands.svg"
                    alt="signature"
                    className="img-fluid"
                  />
                </div>
                <div className="content mt-2 mx-3">
                  <h2 className="small-heading">
                    WHO TRUST US <span className="yellow">50M+ BRANDS</span>
                  </h2>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="owl-carousel owl-theme brand-slider">
                  <div className="item">
                    <a href="#">
                      <div className="crad-1">
                        <img
                          width={130}
                          height={70}
                          src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/barand/bissell.svg"
                          alt="Bissell"
                        />
                      </div>
                    </a>
                    <a href="#">
                      <div className="crad-1">
                        <img
                          width={130}
                          height={70}
                          src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/barand/bosch.svg"
                          alt="Bosch"
                        />
                      </div>
                    </a>
                    <a href="#">
                      <div className="crad-1">
                        <img
                          width={130}
                          height={70}
                          src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/barand/enfamil-v1.svg"
                          alt="Enfamil"
                        />
                      </div>
                    </a>
                  </div>
                  <div className="item">
                    <a href="#">
                      <div className="crad-1">
                        <img
                          width={130}
                          height={70}
                          src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/barand/funko.svg"
                          alt="Bissell"
                        />
                      </div>
                    </a>
                    <a href="#">
                      <div className="crad-1">
                        <img
                          width={130}
                          height={70}
                          src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/barand/breville.svg"
                          alt="Breville"
                        />
                      </div>
                    </a>
                    <a href="#">
                      <div className="crad-1">
                        <img
                          width={130}
                          height={70}
                          src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/barand/applaws.svg"
                          alt="Applaws"
                        />
                      </div>
                    </a>
                  </div>
                  <div className="item">
                    <a href="#">
                      <div className="crad-1">
                        <img
                          width={130}
                          height={70}
                          src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/barand/garmin.svg"
                          alt="Bissell"
                        />
                      </div>
                    </a>
                    <a href="#">
                      <div className="crad-1">
                        <img
                          width={130}
                          height={70}
                          src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/barand/fila.svg"
                          alt="Fila"
                        />
                      </div>
                    </a>
                    <a href="#">
                      <div className="crad-1">
                        <img
                          width={130}
                          height={70}
                          src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/barand/birkenstock.svg"
                          alt="Birkenstock"
                        />
                      </div>
                    </a>
                  </div>
                  <div className="item">
                    <a href="#">
                      <div className="crad-1">
                        <img
                          width={130}
                          height={70}
                          src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/barand/advion.svg"
                          alt="Bissell"
                        />
                      </div>
                    </a>
                    <a href="#">
                      <div className="crad-1">
                        <img
                          width={130}
                          height={70}
                          src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/barand/driclor_solution-v1.svg"
                          alt="Driclor-solution"
                        />
                      </div>
                    </a>
                    <a href="#">
                      <div className="crad-1">
                        <img
                          width={130}
                          height={70}
                          src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/barand/anker.svg"
                          alt="Anker"
                        />
                      </div>
                    </a>
                  </div>
                  <div className="item">
                    <a href="#">
                      <div className="crad-1">
                        <img
                          src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/barand/revlon.svg"
                          alt="Revlon"
                        />
                      </div>
                    </a>
                    <a href="#">
                      <div className="crad-1">
                        <img
                          width={130}
                          height={70}
                          src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/barand/gnc.svg"
                          alt="GNC"
                        />
                      </div>
                    </a>
                    <a href="#">
                      <div className="crad-1">
                        <img
                          width={130}
                          height={70}
                          src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/barand/angelus.svg"
                          alt="Angelus"
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="gallery-popup">
            <div className="gallery">
              <button type="button" className="close btn">
                <i className="fa-solid fa-xmark" />
              </button>
              <div className="gallery-slider owl-carousel owl-theme">
                <div className="item">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-image/slide13.jpg.webp"
                    alt="ubuylife"
                    className="border-radius"
                  />
                </div>
                {/* 0  */}
                <div className="item">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-image/slide22.jpg.webp"
                    alt="ubuylife"
                    className="border-radius"
                  />
                </div>
                {/* 1 */}
                <div className="item">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-image/slide17.jpg.webp"
                    alt="ubuylife"
                    className="border-radius"
                  />
                </div>
                {/* 2 */}
                <div className="item">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-image/slide11.jpg.webp"
                    alt="ubuylife"
                    className="border-radius"
                  />
                </div>
                {/* 3 */}
                <div className="item">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-image/slide15.jpg.webp"
                    alt="ubuylife"
                    className="border-radius"
                  />
                </div>
                {/* 4 */}
                <div className="item">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-image/slide20.jpg.webp"
                    alt="ubuylife"
                    className="border-radius"
                  />
                </div>
                {/* 5 */}
                <div className="item">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-image/slide24.jpg.webp"
                    alt="ubuylife"
                    className="border-radius"
                  />
                </div>
                {/* 6 */}
                <div className="item">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-image/slide14.jpg.webp"
                    alt="ubuylife"
                    className="border-radius"
                  />
                </div>
                {/* 7 */}
                <div className="item">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-image/slide7.jpg.webp"
                    alt="ubuylife"
                    className="border-radius"
                  />
                </div>
                {/* 8 */}
                <div className="item">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-image/slide6.jpg.webp"
                    alt="ubuylife"
                    className="border-radius"
                  />
                </div>
                {/* 9 */}
                <div className="item">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-image/slide19.jpg.webp"
                    alt="ubuylife"
                    className="border-radius"
                  />
                </div>
                {/* 10 */}
                <div className="item">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-image/slide12.jpg.webp"
                    alt="ubuylife"
                    className="border-radius"
                  />
                </div>
                {/* 11  */}
                <div className="item">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-image/slide9.jpg.webp"
                    alt="ubuylife"
                    className="border-radius"
                  />
                </div>
                {/* 13  */}
                <div className="item">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-image/slide5.jpg.webp"
                    alt="ubuylife"
                    className="border-radius"
                  />
                </div>
                {/* 14 */}
                <div className="item">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-image/slide21.jpg.webp"
                    alt="ubuylife"
                    className="border-radius"
                  />
                </div>
                {/* 15  */}
                <div className="item">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-image/slide19.jpg.webp"
                    alt="ubuylife"
                    className="border-radius"
                  />
                </div>
                {/* 16 */}
                <div className="item">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-image/slide10.jpg.webp"
                    alt="ubuylife"
                    className="border-radius"
                  />
                </div>
                {/* 17  */}
                <div className="item">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-image/slide25.jpg.webp"
                    alt="ubuylife"
                    className="border-radius"
                  />
                </div>
                {/* 18 */}
                <div className="item">
                  <img
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-image/slide23.jpg.webp"
                    alt="ubuylife"
                    className="border-radius"
                  />
                </div>
                {/* 19 */}
              </div>
            </div>
          </div>
          <div className="container experience">
            <div className="row ubuy-gallery item-for-desktop mt-9">
              <div className="col-12 col-md-4">
                <div className="gallery-first">
                  <div className="top-part d-flex flex-column gap-4">
                    <div className="grid-row row-first">
                      <div className="img-wrap img-first">
                        <div className="thumbnail-image">
                          <img
                            name="image_0"
                            width={121}
                            height={118}
                            src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/gallery-2.jpg.webp"
                            alt="gallery"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid-row row-second">
                      <div className="img-wrap img-second">
                        <div className="thumbnail-image">
                          <img
                            name="image_1"
                            width={254}
                            height={248}
                            src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/gallery-1.jpg.webp"
                            alt="gallery"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <div className="img-wrap img-third">
                        <div className="thumbnail-image">
                          <img
                            name="image_2"
                            width={163}
                            height={159}
                            src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/gallery-3.jpg.webp"
                            alt="gallery"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="middle-part d-flex flex-column gap-4">
                    <div className="grid-row row-first">
                      <div className="img-wrap img-first" />
                      <div className="img-wrap img-second">
                        <div className="thumbnail-image">
                          <img
                            name="image_3"
                            width={121}
                            height={118}
                            src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/gallery-20.jpg.webp"
                            alt="gallery"
                            className="img-fluid"
                          />
                        </div>
                        <div className="thumbnail-image">
                          <img
                            name="image_4"
                            width={193}
                            height={189}
                            src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/gallery-10.jpg.webp"
                            alt="gallery"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bottom-part d-flex flex-column gap-4 position-relative">
                    <div className="grid-row row-first">
                      <div className="img-wrap img-first">
                        {/* <img src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-11.jpg" alt="gallery" class="img-fluid"> */}
                        <div className="thumbnail-image">
                          <img
                            name="image_5"
                            width={92}
                            height={90}
                            src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/gallery-11.jpg.webp"
                            alt="gallery"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <div className="img-wrap img-second">
                        {/* <img src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-12.jpg" alt="gallery-3" class="img-fluid"> */}
                        <div className="thumbnail-image">
                          <img
                            name="image_6"
                            width={325}
                            height={317}
                            src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/gallery-12.jpg.webp"
                            alt="gallery"
                            className="img-fluid w-100"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid-row">
                      <div className="img-wrap img-third">
                        {/* <img src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-13.jpg" alt="gallery-2" class="img-fluid"> */}
                        <div className="thumbnail-image">
                          <img
                            name="image_7"
                            width={120}
                            height={118}
                            src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/gallery-13.jpg.webp"
                            alt="gallery"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="gallery-second h-100">
                  <div className="grid-row row--first h-100">
                    <div className="img-wrap pt-5 mt-6">
                      {/* <img src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-4.jpg" alt="gallery-4" class="img-fluid w-100"> */}
                      <div className="thumbnail-image">
                        <img
                          name="image_8"
                          width={421}
                          height={400}
                          src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/gallery-4.jpg.webp"
                          alt="gallery"
                          className="img-fluid w-100"
                        />
                      </div>
                    </div>
                    <div className="img-wrap position-relative z-1">
                      <img
                        width={421}
                        height={223}
                        src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/expreince-text.svg"
                        alt="expreince-text"
                        className="img-fluid mt-0 w-100 signature-img"
                      />
                    </div>
                    <div className="img-wrap">
                      <div className="grid-row row-second">
                        <div className="img-first">
                          {/* <img src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-66.jpg" alt="gallery-6" class="img-fluid w-100"> */}
                          <div className="thumbnail-image">
                            <img
                              name="image_9"
                              width={124}
                              height={121}
                              src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/gallery-66.jpg.webp"
                              alt="gallery"
                              className="img-fluid w-100"
                            />
                          </div>
                        </div>
                        <div className="img-second">
                          {/* <img src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-15.jpg" alt="gallery-15" class="img-fluid w-100"> */}
                          <div className="thumbnail-image">
                            <img
                              name="image_10"
                              width={272}
                              height={266}
                              src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/gallery-15.jpg.webp"
                              alt="gallery"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="gallery-third h-100">
                  <div className="top-part d-flex flex-column gap-4 mb-3">
                    <div className="grid-row row-first">
                      <div className="img-wrap img-first">
                        <div className="thumbnail-image">
                          <img
                            name="image_11"
                            width={131}
                            height={118}
                            src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/gallery-5.jpg.webp"
                            alt="gallery"
                            className="img-fluid"
                          />
                        </div>
                        <div className="thumbnail-image">
                          <img
                            name="image_9"
                            width={124}
                            height={121}
                            src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/gallery-6.jpg.webp"
                            alt="gallery"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <div className="img-wrap img-second">
                        {/* <img src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-7.jpg" alt="gallery-7" class="img-fluid"> */}
                        <div className="thumbnail-image">
                          <img
                            name="image_12"
                            width={207}
                            height={189}
                            src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/gallery-7.jpg.webp"
                            alt="gallery"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="middle-part d-flex flex-column gap-4">
                    <div className="grid-row row-first">
                      <div className="img-wrap img-first">
                        {/* <img src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-8.jpg" alt="gallery-8" class="img-fluid w-100"> */}
                        <div className="thumbnail-image">
                          <img
                            name="image_13"
                            width={270}
                            height={265}
                            src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/gallery-8.jpg.webp"
                            alt="gallery"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <div className="img-wrap img-second">
                        {/* <img src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-9.jpg" alt="gallery-9" class="img-fluid w-100"> */}
                        <div className="thumbnail-image">
                          <img
                            name="image_14"
                            width={110}
                            height={100}
                            src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/gallery-9.jpg.webp"
                            alt="gallery"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bottom-part d-flex flex-column gap-4">
                    <div className="grid-row row-first">
                      <div className="img-wrap img-first">
                        {/* <img src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-18.jpg" alt="gallery-18" class="img-fluid w-100"> */}
                        <div className="thumbnail-image">
                          <img
                            name="image_15"
                            width={208}
                            height={188}
                            src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/gallery-18.jpg.webp"
                            alt="gallery"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <div className="img-wrap img-second">
                        {/* <img src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-19.jpg" alt="gallery-19" class="img-fluid w-100"> */}
                        <div className="thumbnail-image">
                          <img
                            name="image_16"
                            width={208}
                            height={188}
                            src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/gallery-19.jpg.webp"
                            alt="gallery"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="grid-row row-second">
                      <div className="img-wrap img-third">
                        {/* <img src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-16.jpg" alt="gallery-16" class="img-fluid w-100"> */}
                        <div className="thumbnail-image">
                          <img
                            name="image_17"
                            width={307}
                            height={300}
                            src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/gallery-16.jpg.webp"
                            alt="gallery"
                            className="img-fluid w-100"
                          />
                        </div>
                      </div>
                      <div className="img-wrap img-fourth">
                        {/* <img src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/gallery-17.jpg" alt="gallery-17" class="img-fluid w-100"> */}
                        <div className="thumbnail-image">
                          <img
                            name="image_18"
                            width={110}
                            height={108}
                            src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/gallery-17.jpg.webp"
                            alt="gallery"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gallery-mobile my-4 item-for-mobile mt-9">
              <div className="img-wrap mb-3 col-7">
                <img
                  src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/expreince-text.svg"
                  alt="expreince-text"
                  className="expreince-text"
                  style={{ height: "auto" }}
                />
              </div>
              <div className="grid-row pt-4">
                <div className="img-wrap img-2 thumbnail-image">
                  <img
                    name="image_12"
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/mobile-gallery8.jpg.webp"
                    alt="gally-mob"
                    className="img-fluid"
                  />
                </div>
                <div className="img-wrap img-3 thumbnail-image">
                  <img
                    name="image_1"
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/mobile-gallery2.jpg.webp"
                    alt="gally-mob"
                    className="img-fluid"
                  />
                </div>
                <div className="img-wrap img-4 thumbnail-image">
                  <img
                    name="image_2"
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/mobile-gallery4.jpg.webp"
                    alt="gally-mob"
                    className="img-fluid"
                  />
                </div>
                <div className="img-wrap img-5 thumbnail-image">
                  <img
                    name="image_13"
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/mobile-gallery3.jpg.webp"
                    alt="gally-mob"
                    className="img-fluid"
                  />
                </div>
                <div className="img-wrap img-6 thumbnail-image">
                  <img
                    name="image_4"
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/mobile-gallery5.jpg.webp"
                    alt="gally-mob"
                    className="img-fluid"
                  />
                </div>
                <div className="img-wrap img-7 thumbnail-image">
                  <img
                    name="image_8"
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/mobile-gallery6.jpg.webp"
                    alt="gally-mob"
                    className="img-fluid"
                  />
                </div>
                <div className="img-wrap img-8 thumbnail-image">
                  <img
                    name="image_6"
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/ubuygallery-test/mobile-gallery7.jpg.webp"
                    alt="gally-mob"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mt-9 blog-post flex-lg-row-reverse">
              <div className="col-lg-3">
                <div className="signature text-center">
                  <img
                    width={234}
                    height={192}
                    src="https://d2ati23fc66y9j.cloudfront.net/ubuylife-v2/blog-image/ubuy-text.svg"
                    alt="ubuy text"
                    className="img-fluid"
                  />
                </div>
                <div className="content mt-2 mx-3 mb-lg-0 mb-4">
                  <h2 className="small-heading text-lg-center text-end">
                    Must <span className="yellow">Reads</span>
                  </h2>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="owl-carousel owl-theme post-slider" />
              </div>
            </div>
          </div>
        </div>
      </>

      <Footer />
    </div>
  );
}

export default Life;
