import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="foot_top">
          <div className="foot_top_body">
            <div className="foot_top_left">
              <ul>
                <li>
                  <Link to="/" onClick={toTop}>
                    Home
                  </Link>
                </li>{" "}
                <li>
                  <Link to="/life" onClick={toTop}>
                    Ubuy Life
                  </Link>
                </li>{" "}
                <li>
                  <Link to="/privacy" onClick={toTop}>
                    Policies and Terms
                  </Link>
                </li>
                <li>
                  <Link to="/ship" onClick={toTop}>
                    Shipping
                  </Link>
                </li>
                <li>
                  <Link to="/about" onClick={toTop}>
                    About us
                  </Link>
                </li>
                <li>
                  <Link to="/contact" onClick={toTop}>
                    Contact us
                  </Link>
                </li>
                <li>
                  <Link to="/enquire" onClick={toTop}>
                    Corporate Enquiries
                  </Link>
                </li>
              </ul>
            </div>
            <div className="foot_top_right">
              <p>Email:team@mubuy.vip</p>
              <p>Name:Beneath Our Wings</p>
              <p>Address:689 WY-31, Manderson, WY 82432</p>
            </div>
          </div>
        </div>
        <hr />
        <div className="foot_bot">
          <div className="foot_bot_inner">
            <div className="foot_bot_one">
              <p>Copyright © 2024 mUbuy Co. All rights reserved.</p>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
