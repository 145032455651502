import React from "react";
import Footer from "./Footer";
import "../css/privacy.css";
import Header from "./Header";

function Security() {
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_box">
        <div className="privacy_box_inner">
          <div className="privacy_body">
            <h1>Shipping Policy</h1>
            <p>
              Ensuring customer satisfaction has always been our utmost
              priority. Our primary goal is to assure that customer shipments
              are delivered safely and within the allotted time interval.
            </p>
            <p>
              Our team closely monitors all packages from dispatch until their
              successful delivery to customers. We hope to build trust and bring
              smiles to our customers’ faces with each delivered order.
            </p>
            <h2>Shipping Process &amp; Procedure</h2>
            <p>
              Product(s) are dispatched from the seller to our warehouse
              facility. The products(s) are then thoroughly examined at our
              warehouse facility before they are dispatched to our customers. We
              carry out timely delivery of these shipments using 3rd party
              courier services that deliver orders to customers on our behalf.
            </p>
            <h2>Shipping Options:</h2>
            <p>
              When you place an order, you can choose the delivery options at
              checkout. The tentative date mentioned in the product description
              has a significant impact on the shipment's transit time.
            </p>
            <h2>Shipping Charges:</h2>
            <p>
              The total shipping charges are calculated at the checkout page.
              The shipping charges vary depending on the product's weight and
              size as well as on the selected shipping option. Shipping charges
              will change with each additional item that is added to your cart.
              Customers can save more on shipping by increasing their basket
              size instead of making single item orders.
            </p>
            <h2>Important Points to Consider for Shipping:</h2>
            <p>
              Make sure you are acquainted with the following indexes
              thoroughly:
            </p>
            <ol>
              <li>
                <h3>Packing Constraints:</h3>
                <p>
                  According to the international aviation organisation's norms
                  and standards, products containing flammable liquids,
                  compressed gases, liquid gases, oxidising agents, and
                  flammable solids are subject to packing constraints based on
                  their volume. Your order will be delivered in multiple
                  packages if it happens to contain such product(s).
                </p>
              </li>
              <li>
                <h3>Shipments Stuck at Customs:</h3>
                <p>
                  As to each such purchase made by the customer through the Ubuy
                  website, the recipient in the destination country in all
                  instances shall be the “Importer of Record” and must comply
                  with all the laws and regulations of said destination country
                  for the product(s) purchased through the Ubuy Website.
                </p>
                <p>
                  The courier company usually takes care of the customs
                  clearance procedure. In case the shipment is held at the
                  customs clearance processes due to missing or absence of
                  proper paperwork/documents/declaration/ government license or
                  certificates required from the ‘Importer of Record’:
                </p>
                <ul>
                  <li>
                    If the ‘Importer of Record’ fails to provide the required
                    documents and paperwork to the custom authorities and as a
                    result the product(s) are confiscated by the customs, Ubuy
                    will not issue a refund. Therefore, we strongly recommend
                    that you make advance preparations &amp; submit the relevant
                    documents when requested by the custom authorities.
                  </li>
                  <li>
                    If the shipment is returned to our warehouse in case of
                    missing/absent paperwork etc. from the customer’s end, a
                    refund will be issued only after deducting the return
                    shipping charges from the product purchase price. The
                    shipping, and custom charges will not be included in the
                    refund.
                  </li>
                </ul>
              </li>
              <li>
                <h3>Undeliverable Shipments/Refused Shipment Returned</h3>
                <p>
                  When a shipment is approved by customs authorities, the
                  concerned courier company will contact the customer and
                  arrange for order delivery:
                </p>
                <p>
                  In the event the customer does not respond, refuses to accept
                  delivery or declines to pay the applicable duties and taxes
                  due to the carrier upon delivery. The shipment will be
                  returned to the country of origin.
                </p>
                <p>
                  The customer may file a refund claim for the above cases. If
                  the shipment is eligible for a refund per Ubuy Return Policy
                  then the Shipping, Custom and other charges (Tax, Gateway
                  charges etc) will not be included in the refund. The
                  Restocking Fee, Customs &amp;amp; VAT(If Applicable) will also
                  be deducted from the total price of goods affected in the
                  shipment.
                </p>
                <p>
                  If the shipment is not returned or the product(s) is
                  non-returnable, the customer is not eligible for a refund.
                </p>
              </li>
              <li>
                <h3>
                  Prohibited Items &amp; Import Restricted items in the
                  Destination Country:
                </h3>
                <p>
                  Ubuy strives to be compliant with laws and ensures that the
                  product(s) meet the regulatory and safety requirements in the
                  respective countries. However, not all product(s) listed on
                  the Ubuy Website may be available for purchase in your
                  respective country of destination. Ubuy makes no promises or
                  guarantees as to the availability of any product(s) listed on
                  the website as being available in the customer’s respective
                  country of destination.
                </p>
                <p>
                  All products purchased on the Ubuy Website are at all times
                  subject to all export and all Trade and Tariff Regulations of
                  any nation of competent Jurisdiction. With millions of
                  products available on our website/app, it's difficult to
                  filter out those that cannot be shipped due to
                  country-specific customs regulations and procedures.
                </p>
                <p>
                  The Customer who purchases product(s) through the Ubuy Website
                  and/or the recipient of the product(s) in the destination
                  country is/are solely responsible for assuring that the
                  product(s) can be lawfully imported to the destination country
                  as Ubuy and its affiliates make no affirmations,
                  representations or promises of any type regarding the legality
                  of importing any product(s) purchased on the Ubuy website into
                  the country in the world. If the product(s) ordered is a
                  restricted or prohibited and not approved by the custom
                  clearance authorities in the destination country, the customer
                  is not eligible for a refund.
                </p>
              </li>
            </ol>
            <h2>Delay Reasons:</h2>
            <p>
              The estimated delivery window provided by Ubuy reflects the most
              standard delivery. However, some orders may occasionally be
              subject to longer transit time caused by:
            </p>
            <ul
              style={{ listStyle: "none", margin: "0px 10px" }}
              className="privacy_ul_img"
            >
              <li>
                <img
                  alt="Bad weather"
                  class="mr-3"
                  src="https://www.a.ubuy.com.kw/skin/frontend/default/ubuycom-v1/images/bad-weather.svg"
                />{" "}
                <span>Bad weather</span>
              </li>
              <li>
                <img
                  alt="Flight delays"
                  class="mr-3"
                  src="https://www.a.ubuy.com.kw/skin/frontend/default/ubuycom-v1/images/Fligth-delay.svg"
                />{" "}
                <span>Flight delays</span>
              </li>
              <li>
                <img
                  alt="National holidays or Festivals"
                  class="mr-3"
                  src="https://www.a.ubuy.com.kw/skin/frontend/default/ubuycom-v1/images/Holidays.svg"
                />
                National holidays or festivals
              </li>
              <li>
                <img
                  alt="Customs clearance procedures"
                  class="mr-3"
                  src="https://www.a.ubuy.com.kw/skin/frontend/default/ubuycom-v1/images/customs-clearance.svg"
                />{" "}
                <span>Customs clearance procedures</span>
              </li>
              <li>
                <img
                  alt="Natural Calamities"
                  class="mr-3"
                  src="https://www.a.ubuy.com.kw/skin/frontend/default/ubuycom-v1/images/natural-calamities.svg"
                />
                Natural calamities
              </li>
              <li>
                <img
                  alt="Massive Breakout of Disease"
                  class="mr-3"
                  src="https://www.a.ubuy.com.kw/skin/frontend/default/ubuycom-v1/images/massive-breakout-of-disease.svg"
                />{" "}
                <span>Massive breakout of disease.</span>
              </li>
              <li>
                <img
                  alt="Other unforeseen circumstances"
                  class="mr-3"
                  src="https://www.a.ubuy.com.kw/skin/frontend/default/ubuycom-v1/images/unforseen-circunstances.svg"
                />{" "}
                <span>Other unforeseen circumstances</span>
              </li>
            </ul>
            <h2>Shipment Tracking:</h2>
            <p>
              All shipments can be tracked using the Order Id number on our
              tracking page. The option to track an order can be found at bottom
              of our website. App users can view the “track order” option when
              they click on the menu icon on the top-left section of the app.
              The user can click on ‘my orders’ and track the shipment easily.
            </p>
            <p>Feel free to contact our support team for further assistance.</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Security;
