import React from "react";
import Footer from "./Footer";
import "../css/about.css";
import Header from "./Header";
import { Link } from "react-router-dom";

function About() {
  return (
    <div className="main-container">
      <Header />
      <div className="about_box">
        <div className="about_box_inner">
          <div className="about1_box">
            <h1>
              Delivering Happiness <br />
              <span>Worldwide</span>
            </h1>
            <img
              src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/hero.png.webp"
              alt="worldwide delivery"
            />
          </div>

          <div className="about2_box">
            <div className="about_container">
              <div className="about2_top">
                <h2>About Us</h2>
                <img alt="" src="/head/about1.png" />
              </div>

              <div className="about2_bot">
                <h2>United States</h2>
                <p>
                  Discover the latest innovations and{" "}
                  <a href="/" title="US Store">
                    <span
                      style={{
                        color: "#151515",
                        fontWeight: "600",
                        textDecoration: "underline",
                      }}
                    >
                      trends from the United States
                    </span>
                  </a>
                  , where our store has products from renowned American brands.
                  From modern technology to trendy styles, experience the
                  essence of the American lifestyle only through Ubuy.{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="about3_box">
            <div className="about_container adjust-width">
              <div className="about3_left">
                <div className="about3_left_inner">
                  <div className="about3_left_body">
                    <div class="global-presence">
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/global-presence.svg"
                        alt="global presence"
                      />
                      <h3 class="card-heading">Global Presence</h3>
                      <p class="card-para">
                        We've stamped our mark in 180+ countries and counting,
                        making us your go-to international marketplace.
                      </p>
                    </div>
                    <div class="global-presence">
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/payment-methods.svg"
                        alt="curated payment methods"
                      />
                      <h3 class="card-heading">Curated Payment Methods</h3>
                      <p class="card-para">
                        We make your shopping experience convenient and secure
                        with global payment methods like Stripe, Knet, and
                        Paypal, along with local methods depending on your
                        region.
                      </p>
                    </div>
                  </div>

                  <div className="about3_left_body">
                    <div class="Presence">
                      <div class="product-range">
                        <img
                          src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/wide-product-range_icon.svg"
                          alt="wide range of products"
                        />
                        <h3 class="card-heading">Wide Product Range</h3>
                        <p class="card-para">
                          Over 300 million products are waiting for you, picked
                          from the top global fashion, electronics, beauty, and
                          more brands.
                        </p>
                      </div>
                      <div class="product-range">
                        <img
                          src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/customer-support.svg"
                          alt="customer support"
                        />
                        <h3 class="card-heading">Customer Support</h3>
                        <p class="card-para">
                          Our reliable customer support team is here for you
                          always for a seamless shopping experience. We also
                          offer local language support in Arabic, Hindi and
                          English!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="about3_right">
                <div class="about3_content">
                  <h2 class="section-heading">Who We Are</h2>
                  <p class="section-para">
                    {" "}
                    We are your global shopping companions and have been so
                    since our inception in 2012. Today, we've become a
                    cross-border shopping paradise connecting customers in over
                    180 countries with limitless possibilities. We operate
                    online stores in 180+ countries with products from the US,
                    the UK, China, Japan, Hong Kong, Korea, and Turkey. Some of
                    our primary features include:
                  </p>
                </div>
                <img
                  class="iphone-img"
                  src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/iphone.png.webp"
                  alt="about ubuy"
                ></img>
              </div>
            </div>
          </div>

          <div className="about4_box">
            <div className="about_container adjust-width">
              <div className="about4_body">
                <div className="about4_internal">
                  <div class="how-we-work">
                    <h2 class="section-heading">How We Work</h2>
                    <p class="section-para">
                      {" "}
                      At Ubuy, simplicity is our forte. We have a
                      straightforward approach to sweeten your shopping
                      experience.
                    </p>
                  </div>
                </div>
                <div className="about4_internal">
                  <div class="fast-work">
                    <img
                      src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/account-icon.svg"
                      alt="account"
                    />
                    <h3 class="card-heading">Account</h3>
                    <p class="card-para">
                      Create your account to let us offer you a personalised
                      shopping journey.
                    </p>
                  </div>
                </div>
                <div className="about4_internal">
                  <div class="fast-work">
                    <img
                      src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/language.svg"
                      alt="language"
                    />
                    <h3 class="card-heading">Language</h3>
                    <p class="card-para">
                      We offer services in English and local languages for your
                      convenient and pleasant shopping experience.
                    </p>
                  </div>
                </div>
                <div className="about4_internal">
                  <div class="fast-work">
                    <img
                      src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/explore.svg"
                      alt="explore"
                    />
                    <h3 class="card-heading">Explore</h3>
                    <p class="card-para">
                      Use our global search engine to find products and
                      categories effortlessly.
                    </p>
                  </div>
                </div>
                <div className="about4_internal">
                  <div class="fast-work">
                    <img
                      src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/search.svg"
                      alt="search"
                    />
                    <h3 class="card-heading">Search</h3>
                    <p class="card-para">
                      Discover over 300 million brand-new, unique products from
                      the best international brands with a click.
                    </p>
                  </div>
                </div>
                <div className="about4_internal">
                  <div class="fast-work">
                    <img
                      src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/delivery.svg"
                      alt="delivery"
                    />
                    <h3 class="card-heading">Delivery</h3>
                    <p class="card-para">
                      We deliver happiness worldwide through local and global
                      logistics partners.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about3_box">
            <div className="about_container adjust-width">
              <div className="about3_left">
                <div className="about3_left_inner">
                  <div className="about3_left_body">
                    <div class="Presence">
                      <div class="trust-us ubuy-trust">
                        <img
                          src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/genuine.svg"
                          alt="genuine products"
                        />
                        <h4 class="card-heading">Genuine Products</h4>
                        <p class="card-para">
                          {" "}
                          When you shop with Ubuy, every product you purchase is
                          100% genuine. We source our merchandise from authentic
                          suppliers and brands, so you receive only high-quality
                          items. We understand how important trust is in online
                          shopping. Our delivery of genuine products is a
                          cornerstone of that trust.
                        </p>
                      </div>
                      <div class="trust-us ubuy-trust">
                        <img
                          src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/partners.svg"
                          alt="top logistics partners"
                        />
                        <h4 class="card-heading">Top Logistics Partners</h4>
                        <p class="card-para">
                          {" "}
                          Ubuy prioritises quick and secure deliveries. We
                          partner with industry leaders like FedEx and DHL for
                          all your deliveries. These world-renowned logistics
                          providers offer extensive coverage, reliable tracking,
                          and on-time deliveries, ensuring a smooth and
                          transparent shopping experience.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="about3_left_body">
                    <div class="trust-us ubuy-trust">
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/money-back.svg"
                        alt="money back guarantee"
                      />
                      <h4 class="card-heading">Money Back Guarantee</h4>
                      <p class="card-para">
                        {" "}
                        At Ubuy, we're dedicated to ensuring you have a positive
                        shopping experience. To demonstrate our commitment, we
                        offer a 100% Money-Back Guarantee on most of our
                        products. However, certain conditions apply.{" "}
                        <Link
                          to="/policy"
                          style={{ color: "#FFB100", textDecoration: "none" }}
                          title="Return Policy"
                        >
                          * return &amp; refund policy
                        </Link>
                      </p>
                    </div>
                    <div class="trust-us ubuy-trust">
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/transparent.svg"
                        alt="transparent and reliable"
                      />
                      <h4 class="card-heading">Transparent and Reliable</h4>
                      <p class="card-para">
                        {" "}
                        Transparency creates trust, and at Ubuy, we prioritise
                        clear communication and reliable service. Whether
                        tracking your order, understanding product details, or
                        resolving an issue, we provide you with the support you
                        need for a transparent and trustworthy shopping
                        experience.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="about3_right">
                <div class="about3_content">
                  <h2 class="section-heading">Why Our Customers Trust Us</h2>
                  <p class="section-para">
                    There are hundreds of reasons why our customers love and
                    trust us. Some reasons why:
                  </p>
                </div>
                <img
                  class="iphone-img"
                  src="	https://d2ati23fc66y9j.cloudfront.net/about-us/2024/phone-man.png.webp"
                  alt="about ubuy"
                ></img>
              </div>
            </div>
          </div>

          <div className="about5_box">
            <div className="about_container adjust-width">
              <div className="about5_inner">
                <div class="deliver">
                  <h2 class="section-heading"> What We Deliver</h2>
                  <p class="section-para">
                    {" "}
                    Our shelves are stocked with products that bring joy and
                    utility to your life. From the latest tech gadgets to trendy
                    fashion pieces, beauty essentials, and much more, Ubuy
                    brings you a world of quality and variety. Our categories
                    include:
                  </p>
                </div>
                <div class="we-deliver">
                  <ul>
                    <li>
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/health-supplements.svg"
                        alt="health and supplements"
                      />{" "}
                      Health &amp; Supplements
                    </li>
                    <li>
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/frame.svg"
                        alt="fashion"
                      />{" "}
                      Fashion &amp; Jewellery
                    </li>
                    <li>
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/electronics.svg"
                        alt="electronic items"
                      />{" "}
                      Electronics
                    </li>
                    <li>
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/cell-phones.svg"
                        alt="cell phones and other accessories"
                      />{" "}
                      Cell Phones &amp; Accessories
                    </li>
                    <li>
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/health.svg"
                        alt="health and household"
                      />{" "}
                      Health &amp; Household
                    </li>
                    <li>
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/baby.svg"
                        alt="baby and toddler"
                      />{" "}
                      Baby &amp; Toddler
                    </li>
                    <li>
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/home-goods.svg"
                        alt="home goods and appliances"
                      />{" "}
                      Home Goods
                    </li>
                    <li>
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/grocery.svg"
                        alt="grocery foods"
                      />{" "}
                      Grocery &amp; Gourmet Food
                    </li>
                    <li>
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/perfume.svg"
                        alt="perfumes and deo fragrances"
                      />{" "}
                      Perfumes &amp; Fragrances
                    </li>
                    <li>
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/tools-home.svg"
                        alt="tools and home improvements available"
                      />{" "}
                      Tools &amp; Home Improvements
                    </li>
                    <li>
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/office.svg"
                        alt="office related products"
                      />{" "}
                      Office Products
                    </li>
                    <li>
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/beauty.svg"
                        alt="beauty and personal care products"
                      />{" "}
                      Beauty &amp; personal care
                    </li>
                    <li>
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/sports-tools.svg"
                        alt="sports"
                      />{" "}
                      Sports &amp; Tools
                    </li>
                    <li>
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/travel.svg"
                        alt="luggage"
                      />{" "}
                      Luggage and Travel Gear
                    </li>
                    <li>
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/books.svg"
                        alt="books available"
                      />{" "}
                      Books
                    </li>
                    <li>
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/automotive.svg"
                        alt="automotive items"
                      />{" "}
                      Automotive
                    </li>
                    <li>
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/musical.svg"
                        alt="musical instrument "
                      />{" "}
                      Musical Instruments
                    </li>
                    <li>
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/pet.svg"
                        alt="supplies for pet"
                      />{" "}
                      Pet Supplies
                    </li>
                    <li>
                      <img
                        src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/sports-outdoors.svg"
                        alt="outdoor sports"
                      />{" "}
                      Sports Outdoors
                    </li>
                  </ul>
                </div>
                <div class="deliver-join">
                  <p>
                    {" "}
                    Join us on this journey to explore the world with Ubuy, and
                    let us deliver happiness to your doorstep.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="about6_box">
            <div className="about_container adjust-width">
              <div className="about6_inner">
                <div class="col-lg-12">
                  <div class="ubuy-journey">
                    <h2 class="section-heading">Ubuy’s Journey</h2>
                    <p>A Timeline of Excellence</p>
                  </div>
                </div>
                <div className="about6_left">
                  <div class="ubuy-timeline">
                    <h2> 01</h2>
                    <h3 class="heading-2"> 2012 - The Genesis</h3>
                    <p class="para-2">
                      {" "}
                      Ubuy emerged on the e-commerce horizon in 2012 with a
                      vision to redefine the online shopping experience. Our
                      journey begins in Kuwait, laying the foundation for what
                      would soon become a global shopping phenomenon.
                    </p>
                  </div>
                  <div class="ubuy-timeline ubuy-timeline-3">
                    <h2> 03</h2>
                    <h3 class="heading-2"> 2017-2018 - Crossing Continents</h3>
                    <p class="para-2">
                      {" "}
                      Ubuy took a bold step forward by opening online stores in
                      50+ countries, from New Zealand to India and Australia to
                      South Africa. Our customers get a better shopping
                      experience with a growing range of authentic and premium
                      products.
                    </p>
                  </div>
                </div>
                <div className="about6_mid">
                  <div class="iphone-ubuy">
                    <img
                      src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/ubuy-phone.png.webp"
                      alt="ubuy journey"
                    />
                  </div>
                </div>
                <div className="about6_left">
                  <div class="ubuy-timeline ubuy-timeline-2">
                    <h2> 02</h2>
                    <h3 class="heading-2"> 2013-2016 - Expanding Horizons</h3>
                    <p class="para-2">
                      {" "}
                      With an initial focus on the MENA Region, Ubuy expanded
                      its operations to Saudi Arabia, Qatar, the United Arab
                      Emirates, Turkey, Egypt, Kuwait, and beyond. The journey
                      gained momentum as our international shopping platform
                      took root in diverse cultures.
                    </p>
                  </div>
                  <div class="ubuy-timeline">
                    <h2> 04</h2>
                    <h3 class="heading-2">
                      {" "}
                      2019-Present - A Global Marketplace
                    </h3>
                    <p class="para-2">
                      {" "}
                      Ubuy is reaching new heights by unfurling its operations
                      in 180+ countries and counting. Our international shopping
                      experience brings the world closer to you. With numerous
                      product categories for customers everywhere, you can think
                      and shop without bounds! As we move forward, we aim to
                      dominate the global shopping industry while providing
                      exceptional customer service.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="about6_box">
            <div className="about_container adjust-width">
              <div className="about6_inner">
                <div className="about7_left">
                  <div class="ubuy-life">
                    <h2 class="section-heading"> Ubuy Life</h2>
                    <p class="section-headind-sm">
                      {" "}
                      Where Work Feels Like Home
                    </p>
                    <p class="para-2">
                      {" "}
                      Ubuy Life is a vibrant hub of passion and purpose. We've
                      cultivated a workplace that feels like a second home,
                      where the energy is contagious and the spirit is
                      collaborative. In our offices, creativity thrives, and
                      teamwork is second nature. We're a community together,
                      celebrating diversity and encouraging inventive thinking.
                      We believe in a healthy work-life balance, where a
                      fulfilled life outside work boosts our collective success.
                    </p>
                    <p class="para-2">
                      {" "}
                      At Ubuy, we continuously learn to stay ahead in the
                      e-commerce sector. But beyond all the tasks and projects,
                      Ubuy Life is about people. We're a supportive family,
                      cheering on each other's victories and navigating
                      challenges together. Know more about Ubuy Life and join us
                      at Ubuy, where work is more than a job – an everyday
                      adventure in a welcoming community.
                    </p>
                  </div>
                </div>

                <div className="about7_right">
                  <div class="life-image">
                    <img
                      src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/ubuy-life.png.webp"
                      alt="ubuy life"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="about6_box">
            <div className="about_container adjust-width">
              <div className="about6_inner">
                <div class="customer-support">
                  <h2 class="section-heading">
                    {" "}
                    Customer Support at Ubuy: Your Joy, Our Priority
                  </h2>
                  <p class="section-para">
                    {" "}
                    At Ubuy, along with our products, we also deliver a seamless
                    and delightful shopping experience. Our customer support
                    team ensures your satisfaction every step of the way.
                  </p>
                </div>
                <div className="about6_left">
                  <div class="support">
                    <img
                      src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/time.svg"
                      alt="24/7 support"
                    />
                    <h3 class="heading-2"> 24/7 Support</h3>
                    <p class="para-2">
                      {" "}
                      We understand your questions and concerns can arise
                      anytime. That is why our customer support operates around
                      the clock, with 24/7 assistance. We're here to help
                      whenever you need us, whether you’re an early bird or a
                      night owl.
                    </p>
                  </div>
                  <div class="support" style={{ marginTop: "60px" }}>
                    <img
                      src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/language.svg"
                      alt="native language support"
                    />
                    <h3 class="heading-2"> Native Language Support</h3>
                    <p class="para-2">
                      {" "}
                      Get your emails answered in your native language, making
                      communication smooth and effortless.
                    </p>
                  </div>
                </div>
                <div className="about6_mid">
                  <div class="support-phone">
                    <img
                      src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/iphone12.png.webp"
                      alt="ubuy iphone"
                    />
                  </div>
                </div>
                <div className="about6_left">
                  <div class="support">
                    <img
                      src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/customer-centric.svg"
                      alt="customer centric approach"
                    />
                    <h3 class="heading-2"> Customer-Centric Approach</h3>
                    <p class="para-2">
                      {" "}
                      At Ubuy, we prioritise your needs and concerns so you feel
                      heard and valued. Your satisfaction is our priority, and
                      we always go the extra mile to exceed your expectations.
                    </p>
                  </div>
                  <div class="support" style={{ marginTop: "60px" }}>
                    <img
                      src="https://d2ati23fc66y9j.cloudfront.net/about-us/2024/quick-response.svg"
                      alt="quick response time"
                    />
                    <h3 class="heading-2"> Quick Response Time</h3>
                    <p class="para-2">
                      {" "}
                      Time is of the essence, especially when you have questions
                      or issues. Our quick response times promptly get you the
                      assistance you need to minimise disruptions to your
                      shopping experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="about6_box">
            <div className="about_container adjust-width">
              <div className="about8_box">
                <div className="ubuy-enviromant">
                  <h2 class="section-heading">
                    {" "}
                    Ubuy's Commitment to the Environment
                  </h2>
                  <p class="section-para">
                    {" "}
                    Ubuy is actively working to reduce our carbon footprint by
                    improving logistics channels and exploring green energy
                    sources. Through 3-ply cardboard boxes, we secure your
                    products while reducing packaging materials. You receive
                    your products in a safe and eco-friendly way through these
                    boxes! We also use brown paper bags for packing your
                    products to reduce plastic usage while covering your
                    products properly. Our eco-friendly packaging decreases our
                    carbon footprint and simultaneously secures your packages.
                    We’re committed to creating a sustainable and eco-conscious
                    shopping experience for your better tomorrow.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default About;
