import React, { useState } from "react";
import Footer from "./Footer";
import "../css/contact.css";
import Header from "./Header";
import { Link } from "react-router-dom";

function Contact() {
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    help: "",
    issue: "",
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const errors = {};

    if (!formData.help)
      errors.help = "There are missing entries in the required fields.";
    if (!formData.issue)
      errors.issue = "There are missing entries in the required fields.";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      alert("There are missing entries in the required fields.");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }

    alert("Send Successful!");
    setFormData({
      help: "",
      issue: "",
    });
    setFormErrors({});
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="contact_box" style={{ background: "#fff" }}>
        <div className="contact1_box">
          <img
            src="https://www.you-buy.ca/skin/frontend/default/ubuycom-v1/images/support-bg.svg"
            alt="For Any Further Questions"
            class="support-bg"
            width="1920"
            height="421"
          ></img>
          <div class="overlay-text ">
            <h1 class="h1 text-center mb-3">Contact Us </h1>
            <p class="h2 poppins-font text-center">
              For Any Further Questions.
            </p>
          </div>
        </div>
        <div class="contact2_box">
          <div className="contact2_box_inner">
            <div className="contact2_body">
              <div className="contact2_body_left">
                <div className="contact2_body_left_inner">
                  <img
                    src="https://www.you-buy.ca/skin/frontend/default/ubuycom-v1/images/Money-icon.svg"
                    alt="make money with us"
                    width="76"
                    height="76"
                  ></img>
                  <p className="contact2_make_p">Make Money With Us</p>
                  <p className="contact2_make_p_two">
                    Affiliate programs are common throughout the Internet and
                    offer website owners an additional way to spread the word
                    about their websites.Among others, our program is free to
                    join, easy to sign up and requires no technical knowledge!
                    As our affiliates, you will generate traffic and sales for
                    our website and receive attractive commissions in return.
                    <br />
                    <br />
                    <Link class="theme-color" to="/">
                      Click here for more details
                    </Link>
                  </p>
                </div>
              </div>

              <div className="contact2_body_right">
                <div className="contact2_body_right_inner">
                  <img
                    class="mb-3 img-fluid"
                    src="https://www.you-buy.ca/skin/frontend/default/ubuycom-v1/images/all-language-support-banner-global.png"
                    alt="Calls support"
                    width="617"
                    height="131"
                  />
                  <p class="contact2_body_right_inner_p_one">
                    Drop your query!
                  </p>
                  <form>
                    <div className="form_div">
                      <div className="form_div_inner">
                        <label>How can we help you with ? </label>
                        <select
                          name="help"
                          className="form-control"
                          id="related-select"
                          aria-label="How can we help you with"
                          value={formData.help}
                          onChange={handleChange}
                        >
                          <option value="">--Please select--</option>
                          <option value="order">An order I placed</option>
                          <option value="nonorder">Non–order related</option>
                        </select>
                      </div>
                      <div
                        className="form_div_inner"
                        style={{ marginTop: "25px" }}
                      >
                        <label>Tell us more about your issue </label>
                        <select
                          className="form-control reason-select valid"
                          name="issue"
                          data-gtm-form-interact-field-id="2"
                          aria-invalid="false"
                          value={formData.issue}
                          onChange={handleChange}
                        >
                          <option value="">--Please select--</option>
                          <option value="Where’s my stuff?">
                            Where’s my stuff?
                          </option>
                          <option value="Problem with an order">
                            Problem with an order
                          </option>
                          <option value="Returns and refunds">
                            Returns and refunds
                          </option>
                          <option value="Payment issues">Payment issues</option>
                          <option value="Changes in order">
                            Changes in order
                          </option>
                        </select>
                      </div>

                      <div className="contact_btn">
                        <button class="way-select" onClick={handleFormSubmit}>
                          Email us
                        </button>
                      </div>
                      <div class="form-group  ">
                        <p>
                          <strong class="h2-heading mr-3">
                            Contact Details :-
                          </strong>
                          <span class="dir-ltr d-inline-block">
                            +1 (587) 4094427
                          </span>
                        </p>
                      </div>
                      <div class="form-group" style={{ marginTop: "0" }}>
                        <p>
                          <strong class="h2-heading mr-3 w-100 d-block">
                            English customer support only
                          </strong>{" "}
                        </p>
                      </div>
                      <div class="form-group" style={{ marginTop: "0" }}>
                        <p>
                          <strong class="h2-heading mr-3">Email :-</strong>
                          <span class="dir-ltr d-inline-block">
                            :- team@mubuy.vip
                          </span>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Contact;
